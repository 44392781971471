var Turbolinks = require("turbolinks");

Turbolinks.start();

// We don't use animated progress
Turbolinks.ProgressBar.prototype.refresh = function() {}

// Custom css
Turbolinks.ProgressBar.defaultCSS = ""


// Changes the default 500ms threshold to show progress bar
Turbolinks.BrowserAdapter.prototype.showProgressBarAfterDelay = function() {
    return this.progressBarTimeout = setTimeout(this.showProgressBar, 0);
};


$(document).on('turbolinks:request-start.transition', function() {
     setTimeout(function(){
         // alert('hi');
         $('body').before($('<div class="loading"><div class="loading__bar loading__bar--load"></div></div>'));
     }, 0);
});

$(document).on('turbolinks:load.transition', function() {
    $('.loading__bar').addClass('loading__bar--unload');
});

$(document).on('turbolinks:load.before-cache.transition', function() {
    setTimeout(function(){
         $(document).find('.loading').remove();
     }, 1000);
});
